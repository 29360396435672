<template>
    <FormWrapper>
        <PageTitle title="Add/Update Suggestion" slot="title" link="/helpContent/Suggestion" />
        <br />
        <ValidationObserver ref="validator" >
            <FormRow>
                <div class="col-sm-2">
                    <InputText v-model="form.name" rules="required" label="Name" :readonly="true" />
                </div>
                <div class="col-sm-2">
                    <label class="InputTextArea_label_35O-v">Anonymous</label>
                    <FormYesNo v-model="form.anonymous" name="Anonymous" :disabled="readonly" ></FormYesNo>
                </div>
                <div class="col-sm-2" >
                    <FormSelect label="Department" :items="departmentList" item-name="Name" item-value="ID"  v-model="form.selectedDepartment" :disabled="readonly" />
                </div>
                <div class="col-sm-2" >
                    <FormSelect label="Category" :items="categoryList" item-name="Name" item-value="id"  v-model="form.selectedCategory" :disabled="readonly"  />
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-5">
                    <InputTextArea v-model="form.subject" label="Subject"  :readonly="readonly" />
                </div>
                <div class="col-sm-5">
                    <InputTextArea v-model="form.suggestion" label="Suggestions" rules="required"  :readonly="readonly" />
                </div>
            </FormRow>
            <br>

            <FormRow v-if="userData.roles.indexOf(62)>0">             <!--show only Suggestion Coordinator id=62   -->
                <div class="col-sm-2" >
                    <FormSelect label="Status" :items="statusList" item-name="Name" item-value="id"  v-model="form.statusId" />
                </div>
                <div class="col-sm-6">
                    <InputTextArea v-model="form.comments" label="Comments"  />
                </div>
            </FormRow>
        </ValidationObserver>
        <FormRow>
            <div class="col-sm-6" >
                <ButtonGroup>
                    <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                    <FormButton type="success" @click="sendData()">Save</FormButton>
                </ButtonGroup>
            </div>
        </FormRow>
        <Popup title="Success" type="success" :value="showMessage.isVisible"  @close="() => (showMessage.isVisible = false)" :closable="true">
            {{ showMessage.message }}
            <div slot="footer">
                <FormButton @click="()=>{$router.go(-1)}">Ok</FormButton>
            </div>
        </Popup>
    </FormWrapper>
</template> 

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import loadingMixin from 'Mixins/loadingMixin';
    import FormYesNo from 'Components/form/FormYesNo';
    import {getSuggestionCategory, getSuggestionDepartment, addUpdateSuggestion, getSuggestion,getSuggestionStatus} from '../api';
    import { ValidationObserver } from 'vee-validate';
    export default {
        name: "AddUpdate",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            PageTitle,
            ValidationObserver,
            FormYesNo
        },
        data() {
            return {
                showMessage:{
                    isVisible: false,
                    message: ''
                },
                form:{
                    name:'',
                    anonymous: 'N',
                    selectedDepartment: null,
                    selectedCategory: null,
                    subject: '',
                    suggestion: '',
                    comments:'',
                    statusId:168,
                },
                statusList: [],
                departmentList: [],
                categoryList: [],
                readonly: false,
            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getAllList();
        },
        methods: {
            getDataByFilter() {
                this.form.name = this.userData.user_name;
                if(this.$route.params.id){
                    setTimeout(() => {
                        this.showLoader();
                        const filters = {};
                        filters.suggestionId = atob(this.$route.params.id);
                        const data = {
                            filterjson: {
                                filter: [filters]
                            }
                        };
                        getSuggestion(data)
                            .then(this.handleResponse)
                            .catch(this.handleError)
                    },
                    50
                    )
                }
            },
            sendData(){
                this.$refs.validator.validate().then(result=>{
                    if(result){
                        this.showMessage.isVisible=false;
                        const data={
                            suggestionId: this.$route.params.id?atob(this.$route.params.id):0,
                            staffId: this.userData.user_id,
                            isAnonymous: (this.form.anonymous == 'Y')?1:0,
                            departmentId: this.form.selectedDepartment,
                            categoryId: this.form.selectedCategory,
                            subject: this.form.subject,
                            statusId:this.form.statusId,
                            suggestion: this.form.suggestion,
                            comments: this.form.comments,
                        };
                        addUpdateSuggestion(data).then((res)=>{
                            this.form = {};
                            this.showMessage.isVisible=true;
                            this.showMessage.message=res.data[0].message;
                        })
                    }
                })
            },
            handleResponse(response) {
                this.hideLoader();
                this.form.name = response.data[0].Name;
                this.form.anonymous = (response.data[0].isAnonymous == 1)?"Y":"N";
                this.form.selectedDepartment = response.data[0].departmentId;
                this.form.selectedCategory = response.data[0].categoryId;
                this.form.subject = response.data[0].subject,
                this.form.suggestion = response.data[0].suggestion;
                this.form.comments = response.data[0].comments?response.data[0].comments:'';
                this.form.statusId = response.data[0].statusId;
                if(this.userData.roles.indexOf(62)>0){ //disable all field when Suggestion Coordinator(id=62) role is view 
                    this.readonly=true;
                }
            },
            async getAllList() {
                await getSuggestionCategory().then((res)=>{
                    this.categoryList=res.data;
                })
                getSuggestionDepartment().then((res)=>{
                    this.departmentList=res.data;
                });
                if(this.$route.params.id){
                    await getSuggestionStatus().then((res)=>{
                    this.statusList=res.data;
                })
                }
                this.getDataByFilter();
            },
        }
    }
</script>
<style lang="scss">
    body{
        padding-right: 0px !important;
        overflow: auto !important;
    }
    textarea{
        height: 105px !important;
    }
</style>